export const SUBSCRIBE = '/subscribe';
export const REPORT = '/report';
export const ACTIVITY = '/activity';
export const SEARCH = '/search';
export const DEMO = '/demo';
export const DEMO_SHORT = '/demo-short';
export const BECOME_PARTNER = '/become-partner';
export const CONNECT_CHANNEL_MANAGER = '/connect-channel-manager';
export const WHITE_PAPER = '/white-paper';
export const VSA = '/vsa';

export const LICENSES = '/licenses';
export const LICENSES_DOWNLOAD = `${LICENSES}/download`;
export const LICENSES_DOWNLOAD_URL = `${LICENSES_DOWNLOAD}/url`;
